import {ref, computed, watch,onMounted} from 'vue';

const MOBILE_SIZE = 1050;

export const isMobile  = ref(false);

function checkFooterState(){
    const windowWidth = window.innerWidth;
    if(windowWidth >= MOBILE_SIZE){
        isMobile.value = false;
    }else{
        isMobile.value = true;
    }
}


watch(()=> window.innerWidth, () =>{
    checkFooterState();
},{immediate: true});

window.addEventListener("resize",()=>{
    checkFooterState();
});