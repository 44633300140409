export const isNative = true;
export const navbarDefaultWidth = '500px';
import { firestore } from '@/util/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import router from '../../router';
import { useAuth } from '@/store/auth';

export type zohoNavItem = {
	text: string
	url?: string,
	section: 'destinations' | 'registration' | 'whoCanGo' | 'about' | 'answers' | 'insideHXP' | 'getAnswers' | 'legalDisclaimer' | 'contactUs',
	subSection?: string
	order?: number
	region: 'header' | 'footer'
}


export async function getZohoNavLinks(){

    const zohoCollection = collection(firestore, "ZohoNavLinks");

    const links: zohoNavItem[] = [];
    const q = query(zohoCollection, where("region", "==", "header"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        links.push(doc.data() as zohoNavItem);
    });

	return links;
}

export function routeToDestinations() {
    router.push("/shopping-home");
}

export function logOut() {
    useAuth().signOut();
    router.push('/shopping-home');
}