/* eslint-disable complexity */
/* eslint-disable no-use-before-define */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-depth */
import { DocumentEntity, type DataInstance, type SubsetEntity } from "@busy-human/gearbox";
import { Builders, BuilderRecord, Season, BuilderDocuments, AssignedChecklistItem, ChecklistDefinitions } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { ref, computed,watch } from "vue";
import { useAuth } from "./auth";
import { useSeasons } from "./seasons";
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onSnapshot } from 'firebase/firestore';
import { storage } from '@/util/firebase';

import { FeatureFlags } from '@busy-human/vue-component-library';

//Only for test data, delete after greyed
import { useBuilderStore } from "./builder";
import { useTrips } from "./trips";
import { useDestinations } from "./destinations";
import { getAssignedChecklistItemsForBuilder } from "./tmpChecklistData";

export type States = 'none' | 'uploading' | 'waiting' | 'submitting' | 'done' | 'error';

export const useChecklistStore = defineStore('checklist', () => {
    const auth = useAuth();
    const season = useSeasons();

    const builders = useBuilderStore();
    const trips = useTrips();
    const destinations = useDestinations();

    const builderChecklist = ref<{[builderId: string]: {[checklistItemId: string]: DataInstance<AssignedChecklistItem.Model>}}>({});

    const waitingResolves: (() => void)[] = [];
    const isReady = ref(false);

    const builderSubset = ref<null | SubsetEntity<Builders.Model>>(null);
    const builderChecklistDocuments = ref<Record<string, DocumentEntity<AssignedChecklistItem.Model>>>({});

    const useFakeChecklist = ref(false);

    function setupListeners(uid: string) {
        if(!useFakeChecklist.value){
            builderSubset.value = Builders.Collection.getBuildersForUserSubset(uid);

            builderSubset.value.onUpdate(() => {
                if(!builderSubset.value) return;
                for(const b of builderSubset.value.dataItems()) {
                    const seasonId = season.current?.$id;
                    if(!seasonId) return;
                    if(!(b.$id in builderChecklistDocuments.value)) {
                        if(!(b.$id in builderChecklist.value)){
                            builderChecklist.value[b.$id] = {};
                        }
                        const docs = Season.Collection.subCollectionEntity(seasonId, "BuilderRecord").subCollectionEntity(b.$id, "AssignedChecklistItems").subset();

                        docs.onUpdate(() => {
                            builderChecklist.value[b.$id] = {};
                            for(const d of docs.dataItems()) {
                                builderChecklist.value[b.$id][d.$id] = d;
                            }
                        });
                        docs.listen();
                        builderChecklistDocuments.value[b.$id] = docs;
                    }
                }
            });
            builderSubset.value.listen();
        }
    }

    function cleanupListeners() {
        for(const ent of Object.values(builderChecklistDocuments.value)) {
            ent.cleanup();
        }
        builderChecklistDocuments.value = {};
        builderSubset.value?.cleanup();
        builderSubset.value = null;
    }

    watch(() => auth.currentUID, async uid => {
        await season.waitForReady();

        if(uid) {
            await refreshData();
            setupListeners(uid);
        } else {
            builderChecklist.value = {};
            cleanupListeners();
        }
        isReady.value = true;
        waitingResolves.forEach(res => res());
        waitingResolves.length = 0;
    }, { immediate: true });


    const waitForReady = () => {
        if(isReady.value) {return Promise.resolve();}
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

    async function refreshData(seasonId?: string) {
        if(!auth.currentUID) return console.warn("Builder refresh called without being logged in");
        seasonId ??= season.current?.$id;
        if(!seasonId) throw new Error("Season not ready");
        const resolvedSeasonId = seasonId;
        const tmp: typeof builderChecklist.value = {};

        const docs = await Builders.Collection.getBuildersForUser(auth.currentUID);

        const builderDocs = await Promise.all(docs.filter(d => !d.isDeleted).map(async d =>{
            const documents = await Season.Collection.subCollectionEntity(resolvedSeasonId, "BuilderRecord").subCollectionEntity(d.$id, "AssignedChecklistItems").fetchAll();
            return {uid: d.$id, ...documents};
        }));

        useFakeChecklist.value = true;
        const isBetaUser = await FeatureFlags.isFeatureEnabled({ feature: "BUILDER_CHECKLIST", context: "beta" });

        if(isBetaUser){
            useFakeChecklist.value = false;
        }else{
            useFakeChecklist.value = true;
        }

        //Toggle this to simulate either Beta Tester view or normal view
        // useFakeChecklist.value = false;

        for(const builderDoc of builderDocs){
            if(!tmp[builderDoc.uid]){
                tmp[builderDoc.uid] = {};
            }

            if(useFakeChecklist.value){
                //TODO: Remove this once we no longer need it
                const builderInfo = builders.builders[builderDoc.uid];
                const builderRecord = builders.builderRecords[builderDoc.uid];
                const builderTrip = trips.trips[builderRecord?.tripAssigned || ''];
                const builderDestination = destinations.destinations[builderTrip?.destination || ''];
                const userChecklistInfo = getAssignedChecklistItemsForBuilder(builderInfo, builderRecord, builderTrip, builderDestination);
                userChecklistInfo.forEach((item: AssignedChecklistItem.Model) => {
                    const fakeId = `FAKEITEM-${Builders.Collection.makeId()}`;
                    tmp[builderDoc.uid][fakeId] = {$id: fakeId, ...item};
                });
            }else{
                builderDoc.items.forEach((item: DocumentEntity<AssignedChecklistItem.Model>) => {
                    tmp[builderDoc.uid][item.$id] = item.data();
                });
            }
        }

        // eslint-disable-next-line require-atomic-updates
        builderChecklist.value = tmp;

        // console.log("BuilderChecklist", builderChecklist.value);
    }

    watch(() => season.current?.$id, async (seasonId, old) => {
        if(seasonId && old && (seasonId !== old)) {
            await refreshData(seasonId);
        }
    });

    async function updateChecklistItem(builderId: string, assginedChecklistId: string, status: "completed" | "in-progress" | "not-started" | "issue"){
        const checklistItem = builderChecklist.value?.[builderId]?.[assginedChecklistId];
        const seasonId = season.current?.$id;
        if(!seasonId) throw new Error("Season not ready");

        if(!checklistItem) return null;
        const oldStatus = checklistItem.status;
        checklistItem.status = status;
        if(oldStatus === 'completed'){
            checklistItem.completionDate = '';
        }
        if(status === 'completed'){
            checklistItem.completionDate = new Date().toISOString();
        }

        if(assginedChecklistId.includes('FAKEITEM')){
            await new Promise(resolve => {setTimeout(resolve, 2000);}); // Simulate Firebase by making it wait
        }else{
            const tmp: AssignedChecklistItem.Model = { ... checklistItem };
            delete (tmp as any).$id;
            Season.Collection.subCollectionEntity(seasonId, "BuilderRecord").subCollectionEntity(builderId, "AssignedChecklistItems").updateDoc(checklistItem.$id, tmp);
        }

        return checklistItem.status;
    }

    return {
        builderChecklist,
        waitForReady,

        updateChecklistItem,

        useFakeChecklist
    };
});