/* eslint-disable max-lines-per-function */
import { defineStore } from "pinia";
import { onAuthStateChanged, User, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut as logOut, sendPasswordResetEmail, ParsedToken, getIdTokenResult } from "firebase/auth";
import { auth } from "@/util/firebase";
import { computed, ref } from "vue";
import { FirebaseError } from "@firebase/util";
import { setRaygunUserEventGenerator, updateRaygunUser } from "@/util/raygun-util";
import { analytics  } from "../../src/util/firebase";
import { logEvent } from "firebase/analytics";
import { AuthClaims } from "@busy-human/hxp-library";
import { FeatureFlags } from "@busy-human/vue-component-library";


const AuthErrorMap: {[code: string]: string} = {
    'auth/invalid-email': "Invalid Email",
    'auth/user-not-found': "User Not Found",
    'auth/wrong-password': "Password Invalid",
    'auth/email-already-in-use': "Email Already In Use",
    "auth/invalid-credential": "Invalid Email or Password"
};


function ConvertAuthError(errorCode: string) {
    // return AuthErrorMap[errorCode] || "Unknown"
    return AuthErrorMap[errorCode] || errorCode;
}

export const useAuth = defineStore('auth', () => {
    const user = ref<User | null>(null);
    const claims = ref<ParsedToken | AuthClaims | null>(null);
    const _authready = ref<boolean>(false);
    const authUID = ref<string | null>(null);
    const proxiedUID = ref<string | null>(null);
    const proxiedRootUID = ref<string | null>(null);
    const isProxied = ref(false);

    const waitingResolves: (() => void)[] = [];

    const isAdmin = computed(() => !!claims.value?.isAdmin);
    function setCurrentUID(uid: string, rootUID?: string) {
        if(!isAdmin.value) throw new Error("Cannot change UID unless you're an admin");
        isProxied.value = true;
        proxiedUID.value = uid;
        if(rootUID) {
            proxiedRootUID.value = rootUID;
        }
    }

    onAuthStateChanged(auth, async userAuth => {
        user.value = userAuth;
        if(userAuth) {
            claims.value = (await getIdTokenResult(userAuth)).claims;
            authUID.value = userAuth.uid;
        } else {
            claims.value = null;
            authUID.value = null;
        }
        if(isProxied.value && !isAdmin.value) {
            isProxied.value = false;
            proxiedUID.value = null;
            proxiedRootUID.value = null;
        }
        _authready.value = true;
        updateRaygunUser(userAuth);
        console.log("Auth State Changed", user.value !== null ? "Authenticated" : "Unauthenticated");

        if(isAdmin.value) {
            // eslint-disable-next-line func-names
            (window as any).setUID = function(uid: string) {
                setCurrentUID(uid);
            };
        } else if((window as any).setUID) {delete (window as any).setUID;}

        waitingResolves.forEach(res => res());
        waitingResolves.length = 0;
    });

    const waitForReady = () => {
        if(_authready.value) { /* empty */ }
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

    const isAuthReady = computed(() => _authready.value);
    const isAuthenticated = computed(() => user.value !== null);

    function resetCurrentUID() {
        isProxied.value = false;
        proxiedUID.value = null;
        proxiedRootUID.value = null;
    }

    async function signIn(email: string, password: string) {
        try {
            const creds = await signInWithEmailAndPassword(auth, email, password);
            user.value = creds.user;
            logEvent(analytics, "login",{
                method: "Email"
            });
            return { success: true };
        } catch (e) {
            console.error(e);
            return { success: false, reason: ConvertAuthError((e as FirebaseError).code) };
        }
    }

    async function createUser(email: string, password: string) {
        try {
            const creds = await createUserWithEmailAndPassword(auth, email, password);
            user.value = creds.user;
            logEvent(analytics, "signup",{
                method: "Email"
            });
            return { success: true };
        } catch (e) {
            console.error(e);
            return { success: false, reason: ConvertAuthError((e as FirebaseError).code) };
        }
    }

    function sendPasswordReset(email: string) {
        return sendPasswordResetEmail(auth, email);
    }

    async function signOut() {
        try {
            resetCurrentUID();
            await logOut(auth);
            logEvent(analytics, "signout");
            localStorage.clear();
        } catch (e) {
            console.error(e);
        }
    }

    async function reloadClaims() {
        const token = await user.value?.getIdTokenResult(true);
        claims.value = token?.claims || null;
    }

    setRaygunUserEventGenerator((userData) => {
        if(userData) {
            return {
                identifier: userData.uid,
                email: userData.email || '',
                isAnonymous: false
            };
        } else {
            return {
                identifier: '',
                email: '',
                isAnonymous: true
            };
        }
    });

    const currentUID = computed(() => {
        if(proxiedRootUID.value) return proxiedRootUID.value;
        if(claims.value?.rootUID) return claims.value.rootUID as string;
        if(proxiedUID.value) return proxiedUID.value;
        else return authUID.value;
    });

    /** True if the current user is a sub-user */
    const isSubUser = computed(() => !!proxiedRootUID.value || !!claims.value?.rootUID);

    const subUserUID = computed(() => {
        if(isProxied.value) return proxiedUID.value;
        else return currentUID.value;
    });

    const subUserBuilderId = computed(() => (claims.value?.builderId as string | undefined) || null);

    FeatureFlags.registerCondition({ name: "betaTester", context: "beta" }, () => !!claims.value?.betaTester || !!claims.value?.isAdmin);

    return { user, isAuthReady, isAuthenticated, isSubUser, subUserBuilderId, signIn, createUser, signOut, sendPasswordReset, reloadClaims, claims, isAdmin, currentUID, proxiedUID, authUID, subUserUID, setCurrentUID, resetCurrentUID, isProxied, waitForReady };
});