import {ref, computed, watch,onMounted} from 'vue';
import { events, EventTypes } from "@/events";

const MOBILE_SIZE = 1050;
const SHRINK_SEARCH = 1160;
//const MOBILE_SIZE = 1150;

export const isMobile  = ref(false);

export const shrunkSearch = ref(false);

function checkHeaderState(){
    const windowWidth = window.innerWidth;
    if(windowWidth >= MOBILE_SIZE){
        isMobile.value = false;
    }else{
        isMobile.value = true;
    }

    if(windowWidth >= SHRINK_SEARCH){
        shrunkSearch.value = false;
    }else{
        shrunkSearch.value = true;
    }
}



watch(()=> window.innerWidth, headerHeight =>{
    checkHeaderState();
},{immediate: true});

window.addEventListener("resize",()=>{
    checkHeaderState();
});

const tmpValue = 50;
export const HEADER_HEIGHT = CONFIG.flags.GLOBAL_HEADER ? tmpValue : 0;
export const MOBILE_HEIGHT = tmpValue;
//export const NAVBAR_WIDTH_COLLAPSED = 100;


export const headerHeight = computed(()=>`${isMobile.value ? MOBILE_HEIGHT : HEADER_HEIGHT}px`);